<template>

  <van-nav-bar title="Movie" v-if="!showSearch">
    <template #right>
      <van-icon name="search" size="18" @click="this.showSearch = !this.showSearch" />
    </template>
  </van-nav-bar>

  <form action="/" v-if="showSearch" >
    <van-search
        @keyup.enter="onSearch"
        v-model="keyword"
        show-action
        autocomplete
        placeholder="请输入搜索关键词"
        @search="onSearch"
        @cancel="this.showSearch = !this.showSearch"
    />
  </form>


  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item>
      <van-image
          width="100%"
          src="//cdn.kalasao.com/cover/index.jpg"
      />
    </van-swipe-item>
  </van-swipe>

  <van-notice-bar
      left-icon="volume-o"
      text="本站是个人学习使用,所有视频和图片均来自互联网收集而来，版权归原创者所有，本网站只提供web页面服务，并不提供资源存储，也不参与录制、上传、传播。如需删除联系：510413498@qq.com"
  />

  <div>

    <div v-if="!showSearch">
      <van-cell><span style="font-weight: bold">历史记录</span></van-cell>
      <van-cell>
        <van-row gutter="5">
          <van-col span="8" v-for="item in historyList" :key="item.id" >
            <router-link :to="{name:'play',params:{mid:item.id}}" >

              <div class="cover" ref="container">
                <van-image
                    width="100%"
                    height="10rem"
                    :src="item.cover"
                >

                  <van-sticky :container="container"  :offset-bottom="80" position="bottom">
                    <van-progress   color="#5eec60"  :percentage=" (item.time / item.duration) * 100 " pivot-text="" stroke-width="6" />
                  </van-sticky>

                </van-image>

              </div>
              <div class="block" style="color:black;height: 1.5rem;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;width: 100%">
                <span>{{ item.title }}</span>
              </div>
            </router-link>
          </van-col>
        </van-row>
      </van-cell>
    </div>



    <van-cell><span style="font-weight: bold">最新更新</span></van-cell>
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >

      <van-cell >

        <van-row gutter="10" >
          <van-col span="8" v-for="item in list" :key="item.ID" >
            <router-link :to="{name:'play',params:{mid:item.ID}}" >

            <div class="cover">
              <van-image
                  width="100%"
                  height="10rem"
                  :src="item.Cover"
              >
                <div class="tag" v-if="item.Type === 0">
                  <van-tag plain  type="primary">更新至{{item.Episodes}}集</van-tag>
                </div>
              </van-image>

            </div>
            <div class="block" style="color:black;height: 1.5rem;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;width: 100%">
              <span>{{ item.Title }}</span>
            </div>
            </router-link>
          </van-col>
        </van-row>




      </van-cell>

    </van-list>

  <foot></foot>
  </div>
</template>

<script>

import {movieInfo} from '@/http/play'
import foot from '@/components/Foot'
import {ref} from "vue";
// import BScroll from '@better-scroll/core'

// import {Toast} from "vant";

export default {
  components:{foot},
  data() {
    return {
      list: [],
      historyList: [],
      page: 1,
      loading: false,
      finished: false,
      showSearch: false,
      keyword: "",
    };
  },
  watch:{
    page:function (val){
      if (val === 1){
        this.list = []
      }
    }
  },
  mounted() {

  },
  setup() {
    const container = ref(null);
    return { container };
  },
  methods: {
    onLoad() {
      // 异步更新数据
      if (this.loading){
        return
      }
      this.loading = true;
      movieInfo(this.page, this.keyword).then(function (response) {
        let data = response.data

        this.historyList = data.history

        if (data.list.length > 0) {
          for (let i = 0; i < data.list.length; i++) {
            this.list.push(data.list[i])
          }
          // 加载状态结束
          this.loading = false;
          this.page++
        } else {
          // 数据全部加载完成
          this.finished = true;
        }
      }.bind(this))
    },
    onSearch(){
      this.page = 1
      this.onLoad()
    }
  },
};
</script>


<style  scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  /*line-height: 150px;*/
  text-align: center;
  /*background-color: #39a9ed;*/
}

.cover {

}

.cover > img {
  width: 100%;
}

.cover .tag {
  position: absolute;
  right: 0.3rem;
  bottom: 0.3rem;
}

.title {

}


</style>